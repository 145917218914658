import { useCallback, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "@/hooks/browser/useClickOutside";
import { useDebounce } from "@/hooks/core/useDebounce";
import { ColorPickerProps } from "./types";

import "./styles.scss";

export const ColorPicker = ({ color, dataQA, id, withDebounce, onChange }: ColorPickerProps) => {
  const popover = useRef();

  const [isOpen, toggle] = useState(false);
  const [value, setValue] = useState(color);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const debounceValue = useDebounce(value, 35);

  useEffect(() => {
    if (withDebounce) {
      onChange && onChange(debounceValue);
    }
  }, [debounceValue]);

  useEffect(() => {
    if (!withDebounce) {
      onChange && onChange(value);
    }
  }, [value]);

  return (
    <div
      className="picker"
      data-qa={dataQA}>
      <div
        className="swatch"
        data-testid="color-picker"
        id={`color-picker-${id}`}
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div
          className="popover"
          ref={popover}>
          <HexColorPicker
            color={color}
            onChange={setValue}
          />
        </div>
      )}
    </div>
  );
};
