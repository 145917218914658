import React from "react";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import { QRFRameProps } from "../types";

import "../styles.scss";

export const cafeFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="83"
      height="96"
      viewBox="0 0 77 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M71.6772 33.6772C69.9122 35.4421 67.6023 36.5366 65.1429 36.7969L65.1429 15.2031C67.6023 15.4634 69.9122 16.5579 71.6772 18.3228C73.7133 20.359 74.8571 23.1205 74.8571 26C74.8571 28.8795 73.7133 31.641 71.6772 33.6772Z"
        fill={frameColor}
        stroke="#404040"
        strokeWidth="2.28571"
      />
      <path
        d="M9 4C9 2.34315 10.3431 1 12 1H62C63.6569 1 65 2.34315 65 4V44C65 50.0751 60.0751 55 54 55H20C13.9249 55 9 50.0751 9 44V4Z"
        fill={frameColor}
        stroke="#404040"
        strokeWidth="2"
      />
      <path
        d="M0 52.6667C0 52.2985 0.298477 52 0.666667 52H74.3333C74.7015 52 75 52.2985 75 52.6667C75 56.7168 71.7168 60 67.6667 60H7.33333C3.28325 60 0 56.7168 0 52.6667Z"
        fill="#404040"
      />
      <foreignObject
        width="60%"
        height="45px"
        style={{ borderRadius: "3px" }}
        x="14"
        y="4">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            borderRadius: "0px",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: "white",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="72">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="5"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
