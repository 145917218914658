import { clone } from "lodash";

import { omittedValues } from "./qrEditorHelper";
import { isSubscriptionActive } from "@/services/UserService";
import { QREditorAPItoDataConversor } from "@/services/ApiConversorService/QREditor/QrEditorApiToData";
import { isClient } from "./browser";

import { isUserLoggedIn, isValidToken } from "@/hooks/auth/useLogin";

import { paths } from "./paths";

import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { UpdateIndexInterface } from "./types";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { GetProfileResponse } from "@/interface/api/getProfile/types";
import { UserStatus } from "@/types/user";
import { setPendingActionCookie } from "@/actions/setPendingActionCookie";
import { ActionTypes } from "@/internals/constants/actionTypes";

const dispatchNavigation = (path: string, routerFn: AppRouterInstance, isReplace?: boolean) => {
  if (isClient()) {
    const navigate = isReplace ? routerFn.replace : routerFn.push;

    navigate(path);
  }
};

export const QR_PAGE_INDEX = {
  SELECT_QR_TYPE: 0,
  ADD_CONTENT: 1,
  DESIGN_QR: 2,
  REGISTER: 3,
  PRICING: 4,
  PAYMENT: 5,
};

export const getPageIndexFromUrl = (url: string) => {
  if (!url) return;
  const pageIndex = url.split("/").filter((substr) => substr).length;
  return pageIndex - 1;
};

export const getPageNameFromUrl = (url: string) => {
  if (!url) return;

  const baseUrlLastIndex = url.lastIndexOf("qr-editor");
  const stringWithoutBaseUrl = url.substring(baseUrlLastIndex + 10);

  return stringWithoutBaseUrl.at(-1) === "/"
    ? stringWithoutBaseUrl.slice(0, -1)
    : stringWithoutBaseUrl;
};

export const navigateFromQrToEditor = (
  qrEditorContext: QREditorContextProps<any>,
  data: QrCodeResponse,
  routerFn: AppRouterInstance
) => {
  const qrData = QREditorAPItoDataConversor.getQRData(data);
  const qrType = qrData.type;

  qrEditorContext.setQrData({
    ...qrData,
    currentPageIndex: QR_PAGE_INDEX.ADD_CONTENT,
  });
  qrEditorContext.setIsQRfinished(true);
  dispatchNavigation(`/qr-editor/${qrType}`, routerFn);
};

export const navigateBack = () => {
  if (isClient()) {
    window.history.go(-1);
  }
};

export const navigateToQrPage = (
  type: string,
  pageIndex: number,
  isSubscriptionActive: boolean,
  routerFn: AppRouterInstance,
  isUnregisteredNavigation?: boolean,
  setSource?: React.Dispatch<string>
) => {
  let urlToNavigate = "";

  switch (pageIndex) {
    case QR_PAGE_INDEX.SELECT_QR_TYPE:
      urlToNavigate = paths.qrEditor();
      dispatchNavigation(urlToNavigate, routerFn, isUnregisteredNavigation);

      break;
    case QR_PAGE_INDEX.ADD_CONTENT:
      urlToNavigate = paths.qrEditor(type);
      dispatchNavigation(urlToNavigate, routerFn, isUnregisteredNavigation);

      break;
    case QR_PAGE_INDEX.DESIGN_QR:
      urlToNavigate = paths.qrEditor(type, "design");
      dispatchNavigation(urlToNavigate, routerFn, isUnregisteredNavigation);
      break;
    default:
      if (isUserLoggedIn()) {
        isValidToken().then((profileCall) => {
          profileCall().then((profile: { data: GetProfileResponse }) => {
            const { data } = profile;
            const hasTrial = data.status?.toLowerCase() === UserStatus.TRIAL.toLowerCase();

            if (hasTrial || data?.subscription?.status === "active") {
              urlToNavigate = paths.myQrCodes();
              dispatchNavigation(urlToNavigate, routerFn, isUnregisteredNavigation);
            } else {
              urlToNavigate = paths.pricing();
              dispatchNavigation(urlToNavigate, routerFn, isUnregisteredNavigation);
            }
          });
        });
      } else {
        setPendingActionCookie({
          type: ActionTypes.EDITOR_LOGIN,
          redirectTo: paths.myQrCodes(),
        });
        setSource && setSource("editor");
        routerFn.push(paths.signup());
      }
      break;
  }
};

const updateData = (newIndex: number, qrEditorContext: QREditorContextProps<undefined>) => {
  const newQRData = clone(qrEditorContext?.qrData);

  newQRData.previousPageIndex = newQRData.currentPageIndex;
  newQRData.currentPageIndex = newIndex;

  return newQRData;
};

export const updateIndex = (props: UpdateIndexInterface) => {
  const {
    newIndex,
    qrEditorContext,
    userContext,
    unregisteredNavigation = false,
    request,
    toast,
    router,
    setSource,
  } = props;
  const { qrData } = qrEditorContext;
  if (!qrData.type) return;

  if (qrEditorContext?.validationError) {
    qrEditorContext?.qrData.type === "apps" &&
      toast({
        message: "Add at least one app store link ",
        type: "error",
        marginBottom: 80,
      });
    qrEditorContext?.qrData.type === "social" &&
      toast({
        message: "Add at least one social media ",
        type: "error",
        marginBottom: 80,
      });
    return;
  }

  if (request) {
    request()
      .then((data) => {
        if (data !== undefined) {
          qrEditorContext?.setQrData(
            {
              ...updateData(newIndex, qrEditorContext),
              qrCodeId: data?.id || qrEditorContext.qrData.qrCodeId,
              qrCodeReference: data?.reference || qrEditorContext.qrData.qrCodeReference,
              qrStyle: qrEditorContext.qrData.qrStyle,
            },
            [...omittedValues(qrEditorContext?.qrData.data)]
          );

          navigateToQrPage(
            qrEditorContext?.qrData.type,
            newIndex,
            isSubscriptionActive(userContext),
            router,
            unregisteredNavigation,
            setSource
          );
        }
      })
      .catch((err) => {
        toast({ message: "genericError", type: "error", marginBottom: 80 });
      });
  } else {
    qrEditorContext?.setQrData(updateData(newIndex, qrEditorContext), [
      ...omittedValues(qrEditorContext?.qrData.data),
    ]);
    navigateToQrPage(
      qrEditorContext?.qrData.type,
      newIndex,
      isSubscriptionActive(userContext),
      router,
      unregisteredNavigation,
      setSource
    );
  }
};

export const handleBackButton = (qrEditorContext, routerFn): void => {
  if (
    isUserLoggedIn() &&
    qrEditorContext?.qrData.currentPageIndex === 1 &&
    qrEditorContext.isQRfinished
  ) {
    dispatchNavigation(paths.myQrCodes(), routerFn);
  }

  qrEditorContext?.qrData.currentPageIndex > 0
    ? (qrEditorContext?.setQrData(
        updateData(qrEditorContext?.qrData.currentPageIndex - 1, qrEditorContext),
        [...omittedValues(qrEditorContext?.qrData.data)]
      ),
      navigateBack())
    : !isUserLoggedIn()
      ? dispatchNavigation(paths.home(), routerFn)
      : dispatchNavigation(paths.myQrCodes(), routerFn);
};

export const handleNextButton = (
  globalContext,
  qrEditorContext,
  userContext,
  requestQR,
  toast,
  isGridSelect,
  router,
  setSource?
) => {
  if (qrEditorContext?.qrData.currentPageIndex < QR_PAGE_INDEX.PAYMENT) {
    // Para evitar duplicidad de eventos en Amplitude
    // Se valida si el QR se ha seleccionado en qr-editor o al usar next button
    if (isGridSelect) qrEditorContext?.setGridSelectEvent(true);
    if (
      globalContext.context.formMethods &&
      qrEditorContext?.qrData.currentPageIndex !== QR_PAGE_INDEX.SELECT_QR_TYPE
    ) {
      globalContext.context.formMethods.handleSubmit(() => {
        updateIndex({
          newIndex: qrEditorContext?.qrData.currentPageIndex + 1,
          qrEditorContext,
          userContext,
          request: requestQR,
          toast,
          router,
          setSource,
        });
      })();
    } else {
      updateIndex({
        newIndex: qrEditorContext?.qrData.currentPageIndex + 1,
        qrEditorContext,
        userContext,
        toast,
        router,
      });
    }
  }
};

export const handleQRFooterDirectNavigation = (
  currentPageIndex,
  contextPageIndex,
  qrEditorContext,
  routerFn
) => {
  if (contextPageIndex === 0 && currentPageIndex > 0) {
    if (currentPageIndex === QR_PAGE_INDEX.ADD_CONTENT) {
      const pageName = getPageNameFromUrl(location.pathname);

      qrEditorContext.setQrData({
        ...qrEditorContext.qrData,
        currentPageIndex,
        type: pageName,
        data: {
          ...qrEditorContext.qrData.data,
          typeName: QREditorAPItoDataConversor.QRTypeTransform(pageName),
        },
      });
    } else if (
      currentPageIndex >= QR_PAGE_INDEX.DESIGN_QR ||
      currentPageIndex >= QR_PAGE_INDEX.REGISTER
    )
      dispatchNavigation(paths.qrEditor(), routerFn, true);
  }
};

export enum eventValidateTypes {
  QREDITOR = "qr-editor",
  CTABUTTONS = "cta_buttons",
  BROWSERURL = "browser_url",
}

export const validateEventSource = (eventSource) => {
  if (eventSource) {
    return eventSource !== eventValidateTypes.QREDITOR ? eventSource : undefined;
  } else {
    return eventValidateTypes.BROWSERURL;
  }
};
export const validatePricngEventSource = (eventSource) => {
  if (eventSource) {
    return eventSource !== eventValidateTypes.CTABUTTONS ? eventSource : undefined;
  }
};
